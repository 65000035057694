import isLink from "~/helpers/isLink"
import redirectToDataHref from "~/helpers/redirectToDataHref"

export const init = () => {
  const links = document.querySelectorAll(".table-row-link")
  links.forEach((link) => link.addEventListener("click", handleClick))
}

export const handleClick = (e) => {
  if (isLink(e.target)) return

  redirectToDataHref(e.currentTarget)
}

$(document).ready(init)
