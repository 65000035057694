export function matchCustom(params, data) {
  const lower = (str) => str.toLowerCase()
  const originalMatcher = $.fn.select2.defaults.defaults.matcher
  const result = originalMatcher(params, data)

  const resultsAndChildrenExist =
    result && data.children && result.children && data.children.length
  if (resultsAndChildrenExist) {
    if (
      data.children.length !== result.children.length &&
      lower(data.text).includes(lower(params.term))
    ) {
      result.children = data.children
    }
    return result
  }

  return null
}

export function initializeSelect2() {
  var $selectElement = $("#participant_lecture_series_id")

  if ($selectElement.length) {
    $selectElement.select2({
      matcher: matchCustom,
      theme: "bootstrap4",
      width: "100%",
    })
  }
}

$(document).ready(initializeSelect2)
